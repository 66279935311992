<template>
    <div class="LineItem">
        <div class="Frame">
            <div class="LineItemImage">
                <router-link :to="{name: 'ModelDetail', params:{idCatalog: this.idCatalogo, idCategory: this.idCategoria,idModel: this.idModello} }">
                    <img  :src="this.getUrlImmagineFull(this.urlImmagine)" alt="" />
                </router-link>
            </div>
            <div class="LineItemText">
                <div class="LineItemName"><span>{{ this.risorse["Nome"] }}:</span> <span>{{this.descrizione}}</span></div>
                <div class="LineItemCode"><span>{{ this.risorse["Codice"] }}:</span> <span>{{ this.idModello}}</span></div>
            </div>
            <div class="Button Big">
                <router-link :to="{name: 'ModelDetail', params:{idCatalog: this.idCatalogo, idCategory: this.idCategoria,idModel: this.idModello} }">{{ this.risorse["TaglieColori"] }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>

import {imgMixin, risorseMixin} from "@/mixin.js";

export default{
    name: "Model",
    props:{
        immagine: String,
        descrizione: String,
        urlImmagine: String,
        idModello: String,
        idCategoria: String,
        idCatalogo: String
    },
    mixins: [imgMixin, risorseMixin]
}

</script>
