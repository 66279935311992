<template>
    <Breadcrumbs/>
    <section class="Section">
        <div class="Container">
            <div class="Box">
                <h1>Search results</h1>
                <div class="MainText">
                    <p>You searched for: <strong>{{ this.textSearch }}</strong>; {{this.modelsList.length}} Results.</p>
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>
        <div class="Container">
            <div class="Box">
                <div class="SearchResultList">
                    <Model v-for="model in this.modelsList" 
                        :key="model.idMOdelloi"
                        :urlImmagine="model.UrlImmagine"
                        :descrizione="model.Descrizione"
                        :idModello="model.IdModello"
                        :idCategoria="model.IdCategoria"
                        :idCatalogo="model.IdCatalogo"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import ModelsService from "@/services/models.service.js";
import Model from "@/components/Model.vue";
import store from '@/store';
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default{   

    name: "SearchResult",
    data(){
        return {
            searchText : "",
            searchCatalog : "",
            searchCatalogDescr : "",
            modelsList : []
        }
    },
    computed:{
        textSearch: function(){
            let result = "";
            if(this.searchText){
                result += this.searchText + " - ";
            }
            if(this.searchCatalog){
                result += "Catalog " + this.searchCatalogDescr;
            }

            if(!result)result = "All Products"

            return result;
        }
    },
    components:{
        Model,
        Breadcrumbs      
    },
    methods:{
        setSearchParams(searchText, searchCatalog, searchCatalogDescr){
            this.searchText = searchText;
            this.searchCatalog = searchCatalog;
            this.searchCatalogDescr = searchCatalogDescr;
        },
        setModelsList(modelsList){
            this.modelsList = modelsList
        },
        setAllData(searchText, searchCatalog, searchCatalogDescr, modelsList){
            this.setSearchParams(searchText, searchCatalog, searchCatalogDescr);
            this.setModelsList(modelsList);
        }
    },
    beforeRouteUpdate (to, from, next) {
        let searchText = "";
        let searchCatalog = "";
        let searchCatalogDescr = "";
        if(to.query.searchText){
            searchText =to.query.searchText;           
        }
        if(to.query.searchCatalog){
            searchCatalog = to.query.searchCatalog;
        }
        if(to.query.searchCatalogDescr){
            searchCatalogDescr = to.query.searchCatalogDescr;
        }
        this.setSearchParams(searchText, searchCatalog, searchCatalogDescr);

        ModelsService.filterModels(store.getters.getToken, searchText, searchCatalog)
        .then(
            data => { 
                if(!data){
                    next("/")
                }
                else{
                    this.setModelsList(data);
                    next();
                }
            }
        ).catch(error => {console.log(error)});
    },
    beforeRouteEnter (to, from, next) {                
        let searchText = "";
        let searchCatalog = "";
        let searchCatalogDescr = "";
        if(to.query.searchText){
            searchText =to.query.searchText;           
        }
        if(to.query.searchCatalog){
            searchCatalog = to.query.searchCatalog;
        }
        if(to.query.searchCatalogDescr){
            searchCatalogDescr = to.query.searchCatalogDescr;
        }      

        ModelsService.filterModels(store.getters.getToken, searchText, searchCatalog)
        .then(
            data => { 
                if(!data){
                    next("/")
                }
                else{                    
                    next(c => c.setAllData(searchText, searchCatalog, searchCatalogDescr, data));
                }

            }
        ).catch(error => {console.log(error)});
    }    
}
</script>
<style>
.MainSearch .WizardSelect {
    overflow: hidden;
}
</style>
